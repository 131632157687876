

export const publicKey = `
-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA1NNaBy95DDqMyhWmYC7/
GGccqvyv48eAOret7H6bD9kwiv6pnBpXFM53AgrD5cUQFKWt5hZeYOOtyxuo2rWo
MNBoY+WAfMNrrQ41E2UtZeeOBz2bu2gVFy1e6Z/WRZ2W9f5EnZPYmRh+T7XNvdVl
9s+mERs7kRpYbAODLxhBPjiBZ0VtBJZknAbNzk1Cn8ysA43K5hBBkJZAO7T4V3EK
c1+mZC8inlZyq5bgMDiO8qB7oZuRiTEW9PhUVmGJEOJzJUbiczGSk5T0k0Y2DcvK
wA9C4bqHlSj1SPN5dBl857yOBLubuJLnzq6NDlpWMWWsUiNPQq04APLRtxuH3rB4
IwIDAQAB
-----END PUBLIC KEY-----
`;