var NodeRSA = require("./node-rsa");
import { publicKey } from "./public-mini.js";
import { privateKey } from "./private.js";
/* 注:采用标准模式:公钥加密,私钥解密. 扩展 变态模式:私钥加密,公钥解密 */

/**
 * rsa加密
 * @param {*} data 需要加密的内容,String格式
 * @returns
 */
export function rsaEncrypt(data) {
  return new Promise((resolve, reject) => {
    /* 标准模式 */
    var key = new NodeRSA(publicKey);
    key.setOptions({ encryptionScheme: "pkcs1" });
    let cipherText = key.encrypt(data, "base64");
    resolve(cipherText);
    /* 变态模式 */
    // var key = new NodeRSA(dataStr);
    // let ciphertext = key.encryptPrivate(data, "base64");
    // resolve(ciphertext);
  }).catch((err) => {
    return "权限不足,请联系管理员邮箱shzskx@qq.com"; //JSON.parse
    console.log(err);
  });
}

/* rsa解密 */
export function rsaDecrypt(data) {
  return new Promise((resolve, reject) => {
    // console.log("data", data);
    /* 标准模式 */
    let key = new NodeRSA(privateKey);
    key.setOptions({ encryptionScheme: "pkcs1" });
    let rawText = key.decrypt(data, "utf8");
    resolve(rawText);

    /* 变态模式 */
    // var key = new NodeRSA(dataStr);
    // let rawtext = key.decryptPublic(data, "utf8");
    // resolve(rawtext);
  }).catch((err) => {
    console.log("err==============", err);
    return "权限不足,请联系邮箱shzskx@qq.com"; //JSON.parse
  });
}
