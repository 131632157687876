import VueRouter from "vue-router";
import Vue from "vue";
import config from "./config";
import store from "../store";

//1. 安装
Vue.use(VueRouter);

//2. 创建路由对象
var router = new VueRouter(config);
// 导航守位 拦截
router.beforeEach(async function(to, from, next) {
  // console.log("to", to.path);
  const storeToken = localStorage.getItem("storeToken") || null;

  if (to.name === "Login" || to.name === "Errow") {
    next();
    return;
  }

  if (!storeToken) {
    next({
      name: "Login",
    });
    return;
  }

  if (storeToken !== store.state.userInfo.token) {
    // console.log("storeToken !== store.state.userInfo.token");
    store.dispatch("getUserInfoAction").then(async (res) => {
      if (res.code === 200) {
        await store.dispatch("findActive", {
          filterNavList: store.state.filterNavList,
          path: to.path,
        });
        if (!store.state.active) {
          next({
            name: "Errow",
          });
          return;
        }
        next();
      } else {
        next({
          name: "Login",
        });
      }
    });
    return;
  }

  store.commit("updataActive", ''); //TODO:可优化/当页面不刷新时且切换到其他没有权限的path，active还是之前的值
  if (storeToken === store.state.userInfo.token) {
    // console.log("storeToken === store.state.userInfo.token", store.state);
    await store.dispatch("findActive", {
      filterNavList: store.state.filterNavList,
      path: to.path,
    });
    if (!store.state.active) {
      console.log("前往Errow")
      next({
        name: "Errow",
      });
      return;
    }
    next();
  }
});
export default router;
