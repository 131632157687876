export default {
  routes: [
    /*  登录页 */
    {
      path: "/",
      name: "Login",
      meta: {
        auth: true,
      },
      component: () => import("@/view/login/index.vue"),
    },
    /* 管理端 */
    {
      path: "/management",
      name: "Management",
      component: () => import("@/view/management/index.vue"), //导航
      // redirect: "/management/supAdmin", //重定向到第一个子路由
      children: [
        /* 超级管理员 */
        {
          path: "supAdmin",
          name: "SupAdmin",
          component: () => import("@/view/management/supAdmin/index.vue"), //导航
          children: [
            {
              path: "dashboard",
              name: "Dashboard",
              component: () =>
                import("@/view/management/supAdmin/dashboard/index.vue"),
            },
            {
              path: "mall",
              name: "Mall",
              component: () =>
                import("@/view/management/supAdmin/mall/index.vue"),
              children: [
                {
                  path: "order",
                  name: "MallOrder",
                  component: () =>
                    import("@/view/management/supAdmin/mall/order/index.vue"),
                },
                {
                  path: "store",
                  name: "MallStore",
                  component: () =>
                    import("@/view/management/supAdmin/mall/store/index.vue"),
                },
                {
                  path: "user",
                  name: "MallUser",
                  component: () =>
                    import("@/view/management/supAdmin/mall/user/index.vue"),
                },
                {
                  path: "admin",
                  name: "MallAdmin",
                  component: () =>
                    import("@/view/management/supAdmin/mall/admin/index.vue"),
                },
              ],
            },
            {
              path: "system",
              name: "System",
              component: () => import("@/view/management/system/index.vue"),
              children: [
                {
                  path: "config",
                  name: "SystemConfig",
                  component: () =>
                    import("@/view/management/system/config/index.vue"),
                },
                {
                  path: "send",
                  name: "SystemSend",
                  component: () =>
                    import("@/view/management/system/send/index.vue"),
                },
              ],
            },
          ],
        },

        /* 首页-数据面板 */
        {
          path: "dataChart",
          name: "DataChart",
          component: () => import("@/view/management/dataChart/index.vue"),
        },

        /* 权限 */
        {
          path: "permission",
          name: "Permission",
          component: () => import("@/view/management/permission/index.vue"), //导航
          children: [
            {
              path: "admin",
              name: "PermissionAdmin",
              component: () =>
                import("@/view/management/permission/admin/index.vue"),
            },
            {
              path: "role",
              name: "PermissionRole",
              component: () =>
                import("@/view/management/permission/role/index.vue"),
            },
          ],
        },

        /* 门店管理 */
        {
          path: "store",
          name: "Store",
          component: () => import("@/view/management/store/index.vue"),
          children: [
            {
              path: "list",
              name: "StoreList",
              component: () =>
                import("@/view/management/store/storeList/index.vue"),
            },
            {
              path: "staff",
              name: "Staff",
              component: () =>
                import("@/view/management/store/staff/index.vue"),
            },
            {
              path: "user",
              name: "User",
              component: () => import("@/view/management/store/user/index.vue"),
            },
            {
              path: "coupon",
              name: "Coupon",
              component: () =>
                import("@/view/management/store/coupon/index.vue"),
            },
            {
              path: "goods",
              name: "Goods",
              component: () =>
                import("@/view/management/store/goods/index.vue"),
              children: [
                {
                  path: "goodsKind",
                  name: "GoodsKind",
                  component: () =>
                    import("@/view/management/store/goods/goodsKind/index.vue"),
                },
                {
                  path: "goodsList",
                  name: "GoodsList",
                  component: () =>
                    import("@/view/management/store/goods/goodsList/index.vue"),
                },
              ],
            },
            {
              path: "mallGoods",
              name: "MallGoods",
              component: () =>
                import("@/view/management/store/mallGoods/index.vue"),
              children: [
                {
                  path: "goodsKind",
                  name: "MallGoodsKind",
                  component: () =>
                    import(
                      "@/view/management/store/mallGoods/goodsKind/index.vue"
                    ),
                },
                {
                  path: "goodsList",
                  name: "MallGoodsList",
                  component: () =>
                    import(
                      "@/view/management/store/mallGoods/goodsList/index.vue"
                    ),
                },
              ],
            },
            {
              path: "integralGoods",
              name: "IntegralGoods",
              component: () =>
                import("@/view/management/store/integralGoods/index.vue"),
              children: [
                {
                  path: "goodsKind",
                  name: "IntegralGoodsKind",
                  component: () =>
                    import(
                      "@/view/management/store/integralGoods/goodsKind/index.vue"
                    ),
                },
                {
                  path: "goodsList",
                  name: "IntegralGoodsList",
                  component: () =>
                    import(
                      "@/view/management/store/integralGoods/goodsList/index.vue"
                    ),
                },
              ],
            },
          ],
        },

        /* 会员管理 */
        {
          path: "vipCard",
          name: "MallVipCard",
          component: () => import("@/view/management/vipCard/index.vue"),
          children: [
            {
              path: "vipCardList",
              name: "MallVipCardList",
              component: () => import("@/view/management/vipCard/vipCardList"),
            },
            {
              path: "vipCardRecode",
              name: "MallVipCardRecode",
              component: () =>
                import("@/view/management/vipCard/vipCardRecode"),
            },
          ],
        },

        /* 门店会员订单 */
        {
          path: "order",
          name: "Order",
          component: () => import("@/view/management/order/index.vue"),
        },

        /* 文章配置 */
        {
          path: "articleManage",
          name: "ArticleManage",
          component: () => import("@/view/management/articleManage/index.vue"),
          children: [
            {
              path: "author",
              name: "ArticleManageAuthor",
              component: () =>
                import("@/view/management/articleManage/author/index.vue"),
            },
            {
              path: "articleKind",
              name: "ArticleKind",
              component: () =>
                import("@/view/management/articleManage/articleKind/index.vue"),
            },
            {
              path: "list",
              name: "ArticleManageList",
              component: () =>
                import("@/view/management/articleManage/list/index.vue"),
            },
            {
              path: "love",
              name: "ArticleManageLove",
              component: () =>
                import("@/view/management/articleManage/love/index.vue"),
            },
            {
              path: "records",
              name: "ArticleManageRecords",
              component: () =>
                import("@/view/management/articleManage/records/index.vue"),
            },
            {
              path: "words",
              name: "ArticleManageWords",
              component: () =>
                import("@/view/management/articleManage/words/index.vue"),
            },
          ],
        },
        /* 网站设置 */
        {
          path: "webCenter",
          name: "WebCenter",
          component: () => import("@/view/management/webCenter/index.vue"),
          children: [
            {
              path: "banner",
              name: "Banner",
              component: () =>
                import("@/view/management/webCenter/banner/index.vue"),
            },
            {
              path: "feedback",
              name: "Feedback",
              component: () =>
                import("@/view/management/webCenter/feedback/index.vue"),
            },
            {
              path: "msgWeb",
              name: "MsgWeb",
              component: () =>
                import("@/view/management/webCenter/msgWeb/index.vue"),
            },
          ],
        },
        /* 商家服务 */
        {
          path: "merchant",
          name: "Merchant",
          component: () => import("@/view/management/merchant/index.vue"),
          children: [
            {
              path: "payment",
              name: "Payment",
              component: () =>
                import("@/view/management/merchant/payment/index.vue"),
            },
          ],
        },

        /* 废弃⚠️ */
        {
          path: "examine",
          name: "Examine",
          component: () => import("@/view/management/examine/index.vue"),
        },
        {
          path: "totalDisease",
          name: "TotalDisease",
          component: () => import("@/view/management/totalDisease/index.vue"),
        },
        {
          path: "setUp",
          name: "SetUp",
          component: () => import("@/view/management/setUp/index.vue"),
        },
      ],
    },
    /* 其他页 */
    {
      path: "/errow",
      name: "Errow",
      component: () => import("@/view/error/index.vue"),
    },
  ],
  // mode: "history",
  mode: "hash",
};
