import axios from "axios";
import { request } from "@/utils/request";
import store from "@/store";

/**
 * 登录
 * @param {*} phoneNumber
 * @param {*} verCode
 * @returns
 */
export async function login(data) {
  const resp = await request({
    url: "/admin/sup/login",
    method: "POST",
    data,
    // isEncrypt:false, //是否加密
    // isDecrypt: false, //是否解密
    // headers: {
    //   "Content-Type": "application/x-www-form-urlencoded",
    // },
  });
  // var token = resp.headers.authorization;
  // var storeToken = resp.data.data;
  // 拿到服务器的令牌authorization
  // if (storeToken) {
  //   localStorage.setItem("storeToken", JSON.stringify(storeToken));
  // }
  if (resp.code === 200) {
    // 模拟生成token
    localStorage.setItem("storeToken", resp.data);
    // this.$store.commit("updataUserInfo", resp.data);
    // localStorage.setItem("userInfo", JSON.stringify(resp.data));
  }
  return resp;
}

/**
 * 使用保存的令牌从服务器换取登录信息
 * @returns
 */
export async function queryMyselfInfo(data) {
  const storeToken = localStorage.getItem("storeToken");
  return request({
    url: "/admin/sup/queryMyselfInfo",
    method: "POST",
    data: {
      token: storeToken,
    },
  });
}

/* 注销登录 */
export function loginOut(data) {
  const resp = request({
    url: "/admin/sup/loginOut",
    method: "POST",
    data,
  });
  localStorage.removeItem("storeToken"); //用户token
  store.commit("updataUserInfo", {}); //清除仓库信息
  store.commit("updataFilterNavList", []); //清除菜单信息
  store.commit("updataFilterStoreList", []); //清除门店权信息
  return resp;
}

/**
 * 发送验证码
 * @phoneNumber
 */
// export async function selectProductData(params) {
//   var resp = await axios.get(`/phoneSelectProductInfo/public/asr/sendCode`, {
//     params,
//   });
//   return resp;
// }
