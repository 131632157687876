<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
console.log(
  "%c 艺烩商城管理系统",
  "font-weight: bold; font-size: 40px;color: #12c2e9; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113); margin-bottom: 12px; padding: 5%"
);

console.log(
  "%c 商务合作邮箱: shzskx@qq.com",
  "font-weight: bold; font-size: 40px;color: #c471ed; 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113); margin-bottom: 12px; padding: 5%"
);
// import { mapActions, mapState, mapGetters } from "vuex";
export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    // ...mapState(["userInfo"]),
    // ...mapGetters(['userInfo'])
  },

  methods: {
    // ...mapActions(["getUserInfoAction"]),
  },
  mounted() {},
  created() {},
};
</script>

<style>
#app {
  background: linear-gradient(180deg, #f0f5fc 0%, #f4f5f7 100%);
  /* background: linear-gradient(to bottom right, #1d3b45, #0a1b37); */
  height: 100%;
}
</style>
