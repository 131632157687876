import Vue from "vue";
import App from "./App.vue";
import router from "./routes";
import store from "./store";
// import "./assets/style/./reset.css";
// import "./assets/style/global.css";

import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
Vue.use(Antd);
// Vue.prototype.$antdConfirm = Vue.prototype.$confirm;

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

import VueParticles from "vue-particles"; //粒子特效
Vue.use(VueParticles);

// import * as filters from './filters' // 全局filter

// 加载过滤器
// Object.keys(filters).forEach(key => {
//     Vue.filter(key, filters[key])
// })
/* 全局指令:水波纹 */
import waves from "@/directive/waves/index.js";
Vue.directive("waves", waves);

// Vue.config.productionTip = false
new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
