export const privateKey = `
-----BEGIN PRIVATE KEY-----
MIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQDcjocr5aA6rWs+
t6s4WgNYVnpTTAxjr4LR8jOWUzDimr7LcLf5fAAIWu5/6GVbw9jZUOCMGgAzfAkN
7WnlXeZgaFedwHOy/wqqbSQ/dJgiflnE2dJFmu8eYRcgYUj8phicX3ft94DDNJM/
DJhR4/jGWVBjNwdyb1w2x2Srb/56iebAJO4w2xtr1mY8msTOs8LWzyNal2FUgsmV
UXkdDgFDc3S/LDDTnjz64NXCmXrj7/E84UdFTJhNshfhX+dhsw9gbjoeRiKGPiHH
LH4+Fsc5B0/5HEtAZdyDCG4O98HtaHmbcNLUzFE93YVLUB199AjVhvbKjrP3p6Yj
q4EPFr0zAgMBAAECggEAcUPgtvJdrsSQZsz76RyQyzTd7RkI48imbhrr0y8106mU
gfWJBV5gxemswZMfOlkBa9jU+B4CLG5uV/CsiVYHkUsEc8b5o9rGJ2+VReEin9Qz
VZxnF8fRNNC3h7FuQhEqYmPEuzh3r8e4NpDJSITCpVoIpmsEMAhwWtFbKY+CKHrl
GDyfqMnvq9L0PbZw4736XlGe54MAm8DwxxHxDLuvBcIsT94uDdVCjsv3SWMG5M1M
7YKtu2tWVyEI8/sDhSNSu6COqfXXBHgH9AJEwFoOpHEoy+xUh+1OELjC8XWVROQ4
osFKwox5VV7gnBEX5C3w4nh2e/8wnTsxDQmSz6hsAQKBgQD0FvRhTgN7Sxop2Di5
lX8V4LRA3qfkT7ZbE8duvhBmr5vQvJ3kNZ+SmOdni0Zg0R93BBnlRyjDXuD0A1BV
vS5ru1IV2GBSVfcJIn56/6UKLWg+0oC2PUx/hLnxG/H0dj66SgrlVSjr6DTJYAP9
+TwfRY8NSeVjCfmunOSfHJ1TcwKBgQDnUZyu6+WeIwcVBDfS63Jkk629PBadVw6p
9RMTcqub578prxw4M5ojBPHHI7aHum1G9sIvj88dJOsP3sLHX3YJNpRdoueSCsz+
32JO7C7PX3m5uuxjggUtDs5ecjJ6FLHjxoWHPWCc4KQf74tBnZ94/CazAc4I+A5e
Lihth6f/QQKBgQDd+kIke54O8i3JACoWHC6G5uMGW3az2worqcEkpRSlTe6V1fKB
J4MG/qbzM2W9hnDm+4PRTvVzN0W8o4bbckBVGPV9iFmQhz0xwXzkvriImOLEvwru
sLBxB3NSIefYOfCx2cQu61NbtsDKodb/kTlr9wYxP5KsjtXSRjxjttIcoQKBgQCP
KtHg6ZvaPi+58DxkzNimK0jS4IfWpfUrd8Vwuf5ApjSIphq/JiD2H69M42urodTU
oREawtUbYqgh0GuZXhn+Jcu6EINmvkyWSsfbtQ5bxund7DU2eViexJUxm1hmeryH
D23BgFl1fwvVwDreR6JWDSoejLVqk6zA2ayn7dgqgQKBgQDhsCTLjAPZi6yxLHqZ
6mb/b1Rq8VfB90GHPVFsLYyk7s5+cT4fJyQQ/CAewHdIMS1Yq8DOsM7CAnHp+0nq
K3av7h2CC25SZacgjoHHXmZaVFZY1+yvOsPJN3m3P3lq7wZsPhjg9nJI8NZVUKzS
lsLwQSCX1/k55a7x7uh2JgpEeA==
-----END PRIVATE KEY-----
`;