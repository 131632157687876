import axios from "axios";
import { request } from "@/utils/request";

/**
 * 查询
 * @param {*} data
 * @returns
 */
export function selectStore(data) {
  return request({
    // headers: {
    //   "Content-Type": "application/x-www-form-urlencoded",
    // },
    url: `/admin/store/select`,
    method: "POST",
    data,
  });
}

export function addStore(data) {
  return request({
    url: `/admin/store/add`,
    method: "POST",
    data,
  });
}

export function updateStore(data) {
  return request({
    url: `/admin/store/update`,
    method: "POST",
    data,
  });
}

export function deleteStore(data) {
  return request({
    url: `/admin/store/delete`,
    method: "POST",
    data,
  });
}

/*根据店铺id数组查询店铺列表 */
export function queryMyselfStore(data) {
  return request({
    url: `/admin/store/queryMyselfStore`,
    method: "POST",
    data,
  });
}
