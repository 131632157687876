const CryptoJS = require("./crypto-js");
// 十六位十六进制数作为密钥
// const SECRET_KEY = CryptoJS.enc.Utf8.parse("0123456789012345");
// 十六位十六进制数作为密钥偏移量
const SECRET_IV = CryptoJS.enc.Utf8.parse("0123456789012347");

/**
 * 加密方法
 * @param data 需要加密的数据
 * @param SECRET_KEY 钥匙
 * @returns {string}
 */
export function aesEncrypt({ data, SECRET_KEY }) {
  SECRET_KEY = CryptoJS.enc.Utf8.parse(SECRET_KEY);
  return new Promise((resolve, reject) => {
    if (typeof data === "object") {
      try {
        data = JSON.stringify(data);
      } catch (error) {
        console.log("encrypt error:", error);
      }
    }
    const dataHex = CryptoJS.enc.Utf8.parse(data);
    const encrypted = CryptoJS.AES.encrypt(dataHex, SECRET_KEY, {
      iv: SECRET_IV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    // return encrypted.ciphertext.toString();
    var hexStr = encrypted.ciphertext.toString().toUpperCase(); // 将密文转为hex的字符串
    var oldHexStr = CryptoJS.enc.Hex.parse(hexStr);
    var base64Str = CryptoJS.enc.Base64.stringify(oldHexStr); // 将密文转为Base64的字符串
    resolve(base64Str);
  }).catch((err) => {
    console.log(err);
    return "权限不足,请联系邮箱shzskx@qq.com"; //JSON.parse
  });
}

/**
 * 解密方法
 * @param data 需要解密的数据
 * @param SECRET_KEY 钥匙
 * @returns {string}
 */
export function aesDecrypt({ data, SECRET_KEY }) {
  // console.log("===SECRET_KEY==1111==", SECRET_KEY);
  SECRET_KEY = CryptoJS.enc.Utf8.parse(SECRET_KEY);
  return new Promise((resolve, reject) => {
    //加密返回的base64Str
    const decrypt = CryptoJS.AES.decrypt(data, SECRET_KEY, {
      iv: SECRET_IV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);

    //若上面加密返回是hexStr
    // const encryptedHexStr = CryptoJS.enc.Hex.parse(data);
    // const str = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    // const decrypt = CryptoJS.AES.decrypt(str, SECRET_KEY, {
    //     iv: SECRET_IV,
    //     mode: CryptoJS.mode.CBC,
    //     padding: CryptoJS.pad.Pkcs7
    // });
    // const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);

    //返回解密数据
    // resolve(decryptedStr);  //直接返回字符串格式
    resolve(JSON.parse(decryptedStr)); //解析字符串为对象格式
  }).catch((err) => {
    console.log("err===========", err);
    return "权限不足,请联系邮箱shzskx@qq.com"; //JSON.parse
  });
}