import Vue from "vue";
import Vuex from "vuex";
import { queryMyselfInfo } from "@/services/user/index.js";
import { queryMyselfStore } from "@/services/mall/store.js";
//1. 安装
Vue.use(Vuex);
var store = new Vuex.Store({
  state: {
    //TODO:登录后的token,1天失效
    storeToken: "",
    //用户信息: 导航权限 店铺权限
    userInfo: {},
    //全量导航
    navList: [
      /* 超级管理员 */
      {
        id: "1",
        label: "超级管理员",
        icon: "el-icon-s-custom",
        routerName: "Management",
        children: [
          {
            id: "1-1",
            label: "数据看版", //用户数量 店长数 交易额
            routerName: "Dashboard",
            path: "/management/supAdmin/dashboard",
          },
          {
            id: "1-4",
            label: "Mall会员商城管理",
            routerName: "Mall",
            children: [
              {
                id: "1-4-1",
                label: "平台订单",
                routerName: "MallOrder",
                path: "/management/supAdmin/mall/order",
              },
              {
                id: "1-4-2",
                label: "用户数据",
                routerName: "MallUser",
                path: "/management/supAdmin/mall/user",
              },
              {
                id: "1-4-3",
                label: "门店数据",
                routerName: "MallStore",
                path: "/management/supAdmin/mall/store",
              },
              {
                id: "1-4-4",
                label: "创建账号", //包括控制门店的数量
                routerName: "MallAdmin",
                path: "/management/supAdmin/mall/admin",
              },
            ],
          },
          {
            id: "1-5",
            label: "系统设置",
            routerName: "System",
            children: [
              {
                id: "1-5-1",
                label: "小程序配置", //维护暂停小程序的情况
                routerName: "SystemConfig",
                path: "/management/supAdmin/system/config",
              },
              // {
              //   id: "1-5-2",
              //   label: "发送系统通知", //包括控制门店的数量
              //   routerName: "SystemSend",
              //   path: "/management/supAdmin/system/send",
              // },
            ],
          },
        ],
      },
      /* 管理员 */
      {
        id: "2",
        label: "首页",
        icon: "el-icon-s-data",
        routerName: "DataChart",
        path: "/management/dataChart",
      },
      {
        id: "3",
        label: "后台账号设置",
        icon: "el-icon-s-platform",
        routerName: "PermissionAdmin",
        path: "/management/permission/admin",
      },
      {
        id: "4",
        label: "门店管理",
        icon: "el-icon-s-shop",
        routerName: "Mall",
        children: [
          {
            id: "4-0",
            label: "用户数据",
            routerName: "User",
            path: "/management/store/user",
          },
          {
            id: "4-1",
            label: "门店列表",
            routerName: "StoreList",
            path: "/management/store/list",
          },
          {
            id: "4-2",
            label: "员工管理", //包括门店管理
            routerName: "Staff",
            path: "/management/store/staff",
          },
          {
            id: "4-3",
            label: "优惠券管理", //包括门店管理
            routerName: "Coupon",
            path: "/management/store/coupon",
          },

          {
            id: "4-4",
            label: "门店商品",
            routerName: "Goods",
            children: [
              // {
              //   id: "4-4-1",
              //   label: "小程序页面设置",
              //   routerName: "GoodsKind",
              //   path: "/management/store/goods/kind",
              // },
              {
                id: "4-4-2",
                label: "商品分类",
                routerName: "GoodsKind",
                path: "/management/store/goods/goodsKind",
              },
              {
                id: "4-4-3",
                label: "商品列表",
                routerName: "GoodsList",
                path: "/management/store/goods/goodsList",
              },
            ],
          },
          {
            id: "4-5",
            label: "微商城",
            routerName: "MallGoods",
            children: [
              {
                id: "4-5-1",
                label: "商品分类",
                routerName: "MallGoodsKind",
                path: "/management/store/mallGoods/goodsKind",
              },
              {
                id: "4-5-2",
                label: "商品列表",
                routerName: "MallGoodsList",
                path: "/management/store/mallGoods/goodsList",
              },
            ],
          },
          {
            id: "4-6",
            label: "积分商城",
            routerName: "IntegralGoods",
            children: [
              // {
              //   id: "4-6-1",
              //   label: "商品分类",
              //   routerName: "IntegralGoodsKind",
              //   path: "/management/store/integralGoods/goodsKind",
              // },
              {
                id: "4-6-2",
                label: "商品列表",
                routerName: "IntegralGoodsList",
                path: "/management/store/integralGoods/goodsList",
              },
            ],
          },
        ],
      },
      {
        id: "9",
        label: "会员管理",
        icon: "el-icon-s-custom",
        routerName: "MallVipCard",
        path: "/management/vipCard",
        children: [
          {
            id: "9-1",
            label: "会员列表",
            routerName: "MallVipCardList",
            path: "/management/vipCard/vipCardList",
          },
          {
            id: "9-2",
            label: "会员卡交易记录",
            routerName: "MallVipCardRecode",
            path: "/management/vipCard/vipCardRecode",
          },
        ],
      },
      {
        id: "5",
        label: "内容管理", //TODO:优惠券
        icon: "el-icon-s-comment",
        routerName: "ArticleManage",
        children: [
          {
            id: "5-0",
            label: "人物管理",
            routerName: "ArticleManageAuthor",
            path: "/management/articleManage/author",
          },
          {
            id: "5-1",
            label: "文章分类",
            routerName: "ArticleKind",
            path: "/management/articleManage/articleKind",
          },
          {
            id: "5-2",
            label: "文章管理",
            routerName: "ArticleManageList",
            path: "/management/articleManage/list",
          },
          {
            id: "5-3",
            label: "收藏列表",
            routerName: "ArticleManageLove",
            path: "/management/articleManage/love",
          },
          {
            id: "5-4",
            label: "用户文章记录",
            routerName: "ArticleManageRecords",
            path: "/management/articleManage/records",
          },
          {
            id: "5-5",
            label: "用户词条",
            routerName: "ArticleManageWords",
            path: "/management/articleManage/words",
          },
        ],
      },

      {
        id: "6",
        label: "订单中心", //门店,微商城,积分商城 3大类订单
        icon: "el-icon-s-order",
        routerName: "Order",
        path: "/management/order",
      },
      {
        id: "7",
        label: "商家服务",
        icon: "el-icon-s-custom",
        routerName: "Examine",
        children: [
          {
            id: "7-1",
            label: "续费",
            routerName: "Payment",
            path: "/management/merchant/payment",
          },

          {
            id: "7-2",
            label: "使用指南",
            routerName: "",
            path: "",
          },
          {
            id: "7-3",
            label: "技术支持",
            routerName: "",
            path: "",
          },
        ],
      },
      {
        id: "8",
        label: "网站端配置", //TODO:优惠券
        icon: "el-icon-s-comment",
        routerName: "WebCenter",
        children: [
          {
            id: "8-0",
            label: "网站主图管理",
            routerName: "Banner",
            path: "/management/webCenter/banner",
          },
          {
            id: "8-1",
            label: "意见反馈",
            routerName: "Feedback",
            path: "/management/webCenter/feedback",
          },
          {
            id: "8-2",
            label: "网站通知",
            routerName: "MsgWeb",
            path: "/management/webCenter/msgWeb",
          },
        ],
      },
    ],
    //登录后筛选的导航
    filterNavList: [],
    //登录后筛选的店铺列表
    filterStoreList: [],
    //高亮导航项
    active: "",
  },
  //变更状态
  mutations: {
    updataUserInfo(state, value) {
      state.userInfo = value;
    },
    updataFilterNavList(state, value) {
      state.filterNavList = value;
    },
    updataFilterStoreList(state, value) {
      state.filterStoreList = value;
    },
    updataActive(state, value) {
      state.active = value;
    },
  },
  actions: {
    /* 用户信息权限,店铺信息初始化 */
    async getUserInfoAction({ commit, state, dispatch }) {
      const userInfo = await queryMyselfInfo();
      console.log("userInfo.data");
      const storeList = await queryMyselfStore({
        store_id: userInfo.data.store_id,
      });

      commit("updataUserInfo", userInfo.data);
      commit("updataFilterStoreList", storeList.data);
      // console.log("storeList==================", storeList.data);
      // //用户无权限拦截
      if (!state.userInfo.role_id) {
        return new Promise((reslove, reject) => {
          reslove({
            code: 201,
            routerName: "Errow",
            message: "当前账号权限不足",
          });
        });
      }

      //根据用户权限初始化导航数据
      var copyNavList = JSON.parse(JSON.stringify(state.navList));
      var roleList = state.userInfo.role_id?.split(",");

      // console.log("copyNavList", copyNavList, "roleList======", roleList);
      await dispatch("getFilterNavListAction", {
        copyNavList,
        roleList,
      });
      commit("updataFilterNavList", copyNavList); //根据用户权限完成导航筛选

      //定位第一个导航
      const routerName = await dispatch("findFirstRouterName", copyNavList);

      return new Promise((reslove, reject) => {
        reslove({
          code: 200,
          routerName,
        });
      });
    },

    /* 导航筛选 */
    async getFilterNavListAction(
      { dispatch, commit },
      { copyNavList, roleList }
    ) {
      for (var index = copyNavList.length - 1; index >= 0; index--) {
        if (
          copyNavList[index]?.children &&
          copyNavList[index]?.children.length > 0 &&
          roleList?.indexOf(copyNavList[index].id) >= 0
        ) {
          await dispatch("getFilterNavListAction", {
            copyNavList: copyNavList[index].children,
            roleList: roleList,
          });
        }
        if (roleList?.indexOf(copyNavList[index].id) < 0) {
          copyNavList.splice(index, 1);
        }
      }
    },

    /*  找到第一个导航最深层routerName */
    async findFirstRouterName({ dispatch, commit }, copyNavList) {
      if (copyNavList[0]?.children && copyNavList[0]?.children.length > 0) {
        return await dispatch("findFirstRouterName", copyNavList[0]?.children);
      } else {
        return copyNavList[0].routerName;
      }
    },

    /* 找到导航active */
    async findActive({ dispatch, commit }, { filterNavList, path }) {
      for (let index = 0; index < filterNavList.length; index++) {
        if (filterNavList[index]?.path === path) {
          commit("updataActive", filterNavList[index]?.id);
        }
        if (
          filterNavList[index]?.children &&
          filterNavList[index]?.children.length > 0 &&
          filterNavList[index]?.path !== path
        ) {
          await dispatch("findActive", {
            filterNavList: filterNavList[index]?.children,
            path,
          });
        }
      }
    },
  },

  /* 计算属性 */
  getters: {},
});
export default store;
