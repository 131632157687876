/**
 *
 * @returns 根据时间戳生成n位随机数
 */
function randomFun(n) {
  var tmp = Date.parse(new Date()).toString();
  tmp = tmp.substring(0, n);
  return tmp;
}

/**
 *
 * @returns 根据概率生成n位随机数
 */
function randomNumber(n=4) {
  var charactors = "1234567890";
  var value = "",
    i;
  for (var j = 1; j <= n; j++) {
    i = parseInt(10 * Math.random());
    value = value + charactors.charAt(i);
  }
  return value;
}

/*  格式化日对象  */
function getNowDate() {
  var date = new Date();
  var sign2 = ":";
  var year = date.getFullYear(); // 年
  var month = date.getMonth() + 1; // 月
  var day = date.getDate(); // 日
  var hour = date.getHours(); // 时
  var minutes = date.getMinutes(); // 分
  var seconds = date.getSeconds(); //秒
  // var weekArr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'];
  // var week = weekArr[date.getDay()];
  // 给一位数的数据前面加 “0”
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (day >= 0 && day <= 9) {
    day = "0" + day;
  }
  if (hour >= 0 && hour <= 9) {
    hour = "0" + hour;
  }
  if (minutes >= 0 && minutes <= 9) {
    minutes = "0" + minutes;
  }
  if (seconds >= 0 && seconds <= 9) {
    seconds = "0" + seconds;
  }
  return (
    year + "-" + month + "-" + day
    //+ " " +
    // hour +
    // sign2 +
    // minutes +
    // sign2 +
    // seconds
  );
}
module.exports = {
  randomFun,
  randomNumber,
  getNowDate,
};
