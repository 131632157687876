import {
  IP
} from "@/services/config.js";
import axios from "axios";
import Vue from "vue";
const {
  aesEncrypt,
  aesDecrypt
} = require("@/utils/crypter/aes/index");
const {
  rsaEncrypt,
  rsaDecrypt
} = require("@/utils/crypter/rsa/index");
const {
  randomNumber
} = require("@/utils/tool");
export const token = "";
// import { RSA } from "./crypter/rsa";
// import { AES } from "./crypter/aes";
// import { DEFAULT_PUB_KEY } from "./crypter/rsa/conf";

const isAllEncrypt = true; //是否开启全局加密
const isAllDecrypt = true; //是否开启全局解密

let baseURL = IP;
// const service = axios.create({
//     baseURL,
//     timeout:30000
// });
// console.log(DEFAULT_PUB_KEY);
export let request = axios.create({
  baseURL,
  timeout: 20000,
});
/**
 * request
 * 请求拦截器
 */
request.interceptors.request.use(
  async ({
      isEncrypt = true,
      ...config
    }) => {
      // 配置请求头
      // config.headers["Authorization"] = JSON.parse(
      //   localStorage.getItem("storeToken")
      // )?.storeToken;
      // config.headers["Authorization"] = token;
      // let data = config.data;
      log({
        type: "request",
        url: config.url,
        data: config.data,
      })
      config.headers["X-Access-Token"] = localStorage.getItem("storeToken");
      if (isAllEncrypt && isEncrypt) {
        const SECRET_KEY = randomNumber(16); //动态生成随机aes加密钥匙
        config.data = {
          key: await rsaEncrypt(SECRET_KEY),
          data: await aesEncrypt({
            data: config.data,
            SECRET_KEY,
          }),
        };
      }
      return config;
    },
    (error) => {
      console.error(error);
    }
);

/**
 * 响应拦截器
 */
request.interceptors.response.use(
  async ({
      data,
      config,
      status
    }) => {
      const {
        isDecrypt = true
      } = config;
      if (status !== 200) {
        console.error("网络错误");
        errorMessage({
          message: '网络错误'
        })
        return;
      }
      if (isAllDecrypt && isDecrypt) {
        const SECRET_KEY = await rsaDecrypt(data.key);
        data = await aesDecrypt({
          data: data.data,
          SECRET_KEY,
        });
      }
      log({
        type: "response",
        url: config.url,
        data,
      })
      if (data.code !== 200) {
        errorMessage({
          message: data.message || '网络错误'
        })
      }
      return data;
    },
    (error) => {
      //处理是否为token过去 跳转到登录页
      console.log("error:", error);
      errorMessage({
        message: error || '网络错误'
      })
    }
);


/* 请求错误处理error */
function errorMessage({
  message
}) {
  Vue.prototype.$message.error(message)
  const loading = document.querySelector('.is-fullscreen')
  if (loading) {
    loading.style.display = 'none';
  }
}

/**
 * 打印日志
 * type：日志类型 request/response
 */
function log({
  type,
  url,
  data
}) {
  //请求日志(测试环境打印，线上不打印)
  if (process.env.NODE_ENV === "development") {
    console.log(type, url, ":", data);
  }
}